import Vue from 'vue'
import App from './App.vue'
import Vuetify from 'vuetify'
import router from './router'
import 'vuetify/dist/vuetify.min.css';
import vuetify from './plugins/vuetify'
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import { VueMaskDirective } from 'v-mask'

import VideoBackground from 'vue-responsive-video-background-player'
import VideoBg from 'vue-videobg'
import VueSidebarMenuAkahon from "vue-sidebar-menu-akahon"
import Flipbook from 'flipbook-vue'
import AOS from 'aos'
import 'aos/dist/aos.css'

Vue.component('video-bg', VideoBg);
Vue.use(Vuetify);
Vue.component('vue-phone-number-input', VuePhoneNumberInput);
Vue.component('video-background', VideoBackground);
Vue.directive('mask', VueMaskDirective);
Vue.component('vue-sidebar-menu-akahon', VueSidebarMenuAkahon);
Vue.component('flipbook-vue', Flipbook);

Vue.config.productionTip = false

new Vue({
  components: { VideoBg },
  router,
  vuetify,
  render: h => h(App),
  mounted() {
    AOS.init()
  },
}).$mount('#app')
